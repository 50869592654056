//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-632:_6756,_5904,_248,_5716,_4120,_3872,_3156,_2828;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-632')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-632', "_6756,_5904,_248,_5716,_4120,_3872,_3156,_2828");
        }
      }catch (ex) {
        console.error(ex);
      }